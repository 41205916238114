.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.footer_line {
  font-size: 16px;
  margin-top: 7px;
  text-align: center;
  a {
    color: #feec88;
    font-weight: 700;
  }
}
