* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Gotham;
  text-decoration: none;
  color: #fff;
  scrollbar-width: none;
}
body {
  background-color: #000;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  overflow: -moz-scrollbars-none !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important; /* Firefox */
}
::-webkit-scrollbar {
  display: none !important;
}
#root {
  height: 100vh;
  overflow: hidden;
  scrollbar-width: none;
  position: relative;
}
