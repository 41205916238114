.artist_box__name {
  font-size: 25px;
  font-weight: 700;
}
.card_image {
  width: 100%;
  display: block;
  margin: 0 auto;
  max-height: 372px;
}
.card_artist_name {
  text-align: center;
  margin-top: 34px;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
}
.card_artist_tour_name {
  font-size: 20px;
  margin-top: 7px;
  text-align: center;
  font-weight: 300;
  line-height: 19.14px;
}
.card_box {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 70vh;
}
.tickets_row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 34px;
}
.tickets_box {
  margin-top: 65px;
}
.tickets_city {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 17px;
}
.tickets_date {
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 7px;
}
.book_btn {
  background: #feec88;
  outline: none;
  border: none;
  color: #000;
  font-size: 16px;
  width: fit-content;
  margin: 0 auto;
  font-weight: 400;
  padding: 0 9px;
  padding-top: 3px;
  border-radius: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  line-height: 10px;
  cursor: pointer;
  transition: all 0.4s ease;

  &:hover {
    transform: scale(1.05);
  }
}
.card_artist_date {
  text-align: center;
  margin-top: 36px;
  font-size: 24px;
  font-weight: normal;
}
.card_artist_city {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 8px;
}
.card_tickets_title {
  font-weight: 300;
  font-size: 27px;
  text-align: center;
  margin-top: 65px;
  margin-bottom: 62px;
}
.category_row {
  align-items: flex-start;
}
.tickets_off {
  font-size: 14px;
}

.tickets_description {
  font-size: 16px;
  text-align: center;
  margin-top: 42px;
}
.tickets_description__subtitle {
  font-size: 14px;
  text-align: center;
}
.tickets_description__small {
  font-size: 13px;
  text-align: center;
  margin-top: 42px;
}
.offer_btn {
  margin-top: 42px;
  font-size: 19px;
  height: 32px;

  padding: 0 20px;
  padding-top: 3px;
}
.ticket_end_title {
  font-size: 26px;
  text-align: center;
  span {
    color: #feec88;
  }
}
.ticket_end_subtitle {
  font-size: 12px;
  text-align: center;
}
@media screen and (max-width: 375px) {
  .tickets_city {
    font-size: 16px;
  }
  .book_btn {
    font-size: 15px;
  }
  .main {
    padding: 12px;
  }
}
