.App {
  background-color: #000;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background: url(./assets/bg.jpg);
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
}
.fadeIn {
  animation: 0.3s fadeIn forwards;
  height: 100%;
}

.fadeOut {
  animation: 0.3s fadeOut forwards;
}
.main {
  padding: 24px;
  padding-top: 24px;
  overflow: scroll;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 70vh;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}
@media screen and (min-width: 600px) {
  .App {
    width: 450px;
    margin: 0 auto;
  }
}
