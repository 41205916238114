.artist_box {
  height: 40vw;
  width: 40vw;
  max-width: 165px;
  max-height: 165px;
  background-size: cover;
  background-position: center;
  margin-bottom: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.artist_box__name {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (min-width: 1000px) {
  .artist_box {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 375px) {
  .artist_box {
    height: 45vw;
    width: 45vw;
    margin-bottom: 5vw;
  }
}
