body {
  width: 100vw;
  overflow-x: hidden;
}
@font-face {
  font-family: Gotham;
  src: url("./fonts//gothampro.ttf") format("truetype");
}
@font-face {
  font-family: Gotham;
  src: url("./fonts//gothampro_bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: Gotham;
  src: url("./fonts//gothampro_light.ttf") format("truetype");
  font-weight: 300;
}
 